import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import css from './VersionHistory.module.scss';
import { ReactComponent as EllipseIcon } from '../../../../images/icon/ellipse.svg';
import { ChangeNotices } from 'models';
import { formatDate } from 'shared/utils';
import { buildUrl } from 'shared/http-common';
import { NOTICES } from 'shared-config';

interface Props {
  readonly changeNotices: ChangeNotices[];
}

export default function VersionHistory({ changeNotices }: Props): ReactElement {
  const { t } = useTranslation('translations');

  const getText = (index: number) =>
    `${t('notice.version')} ${changeNotices.length - index}`;

  const getFormattedDate = (issueDate: string | undefined) =>
    issueDate ? formatDate(issueDate) : t('common.not_specified');

  return (
    <div className={css.version_wrap} data-cy="version-history">
      <h2 className={css.title}>{t('notice.version_history')}</h2>
      <ul className={css.list}>
        {changeNotices.map((version, index) => (
          <li key={version.id}>
            <Link
              to={buildUrl([NOTICES, version.id ?? ''])}
              className={classnames(css.version_link, {
                [css.active]: version.selectedNotice
              })}
            >
              <div className={css.version_name}>
                <span className={css.version_nr}>
                  <span className={css.icon}>
                    <EllipseIcon />
                  </span>
                  {getText(index)}
                </span>
                <span className={css.version_date}>
                  {getFormattedDate(version.issueDate)}
                </span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
