import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import css from './MenuSelector.module.scss';
import MenuGroup from './menuGroup/MenuGroup';
import {
  ANSKAFFELSER_HVA_URL,
  ANSKAFFELSER_INNKJOPSLEDELSE_URL,
  ANSKAFFELSER_INNOVASJON_URL,
  ANSKAFFELSER_OM_URL,
  ANSKAFFELSER_PROSESS_URL,
  ANSKAFFELSER_REGELVERK_URL,
  ANSKAFFELSER_SAMFUNNSANSVAR_URL,
  ANSKAFFELSER_HEJLPESIDER_DOFFIN
} from 'shared-config';

export interface MenuItems {
  text: string;
  link: string;
}

export default function MenuSelector(): ReactElement {
  const { t } = useTranslation('translations');

  const menu = useRef<HTMLUListElement>(null);
  const menuButton = useRef<HTMLButtonElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setMenuVisible(!menuVisible);

  const handleMenuItemClick = () => {
    setMenuVisible(false);
  };

  const handleClickOutside = (event) => {
    if (
      menu.current &&
      !menu.current.contains(event.target) &&
      menuButton.current &&
      !menuButton.current.contains(event.target)
    ) {
      setMenuVisible(false);
    }
  };

  const doffinLinks: MenuItems[] = [
    {
      text: t('link.menu_om_doffin'),
      link: ANSKAFFELSER_OM_URL
    }
  ];

  const anskaffelseLinks: MenuItems[] = [
    // {
    //   text: t('link.menu_help_doffin'),
    //   link: ANSKAFFELSER_HEJLPESIDER_DOFFIN
    // },
    {
      text: t('link.menu_hva_skal_du_kjoepe'),
      link: ANSKAFFELSER_HVA_URL
    },
    {
      text: t('link.menu_anskaffelsesprosess'),
      link: ANSKAFFELSER_PROSESS_URL
    },
    {
      text: t('link.menu_avtaler_regelverk'),
      link: ANSKAFFELSER_REGELVERK_URL
    },
    {
      text: t('link.menu_innkjoepsledelse'),
      link: ANSKAFFELSER_INNKJOPSLEDELSE_URL
    },
    {
      text: t('link.menu_samfunnsansvar'),
      link: ANSKAFFELSER_SAMFUNNSANSVAR_URL
    },
    {
      text: t('link.menu_innovasjon'),
      link: ANSKAFFELSER_INNOVASJON_URL
    }
  ];

  return (
    <>
      <button
        type="button"
        className={classnames(css.menu_button, menuVisible && css.cross_button)}
        onClick={toggleMenu}
        ref={menuButton}
        id="menu_button"
        aria-label={t('header.menu')}
        aria-expanded={menuVisible}
        aria-owns="menu_select"
      >
        <span className={css.button_text}>{t('header.menu')}</span>
      </button>
      {menuVisible && (
        <ul
          ref={menu}
          id="menu_select"
          data-cy="menu_select"
          role="menu"
          aria-hidden={menuVisible}
          aria-labelledby="menu_button"
          className={css.menu_list}
        >
          <MenuGroup
            label={'app.title'}
            links={doffinLinks}
            handleMenuItemClick={handleMenuItemClick}
          />
          <MenuGroup
            label={'link.anskaffelser_title'}
            links={anskaffelseLinks}
            handleMenuItemClick={handleMenuItemClick}
            handleLastItemBlurred={toggleMenu}
          />
        </ul>
      )}
    </>
  );
}
